import React, { useState } from "react";
import PrimaryButton from "../../atoms/PrimaryButton";
import imgMain from "../../../assets/pics/Armchair_all.jpeg";
import imgProcess1 from "../../../assets/pics/step1-2.png";
import imgProcess2 from "../../../assets/pics/step2-2.png";
import img360 from "../../../assets/pics/360_views.gif";
import img360Blue from "../../../assets/pics/360_views_blue.gif";
import roundView from "../../../assets/pics/3d.png";
import dotRed from "../../../assets/pics/dot-red.png";
import dotBlue from "../../../assets/pics/dot-blue.png";
import customisablePic from "../../../assets/pics/Kitchen_Scene.gif";
import imgRoom3d from "../../../assets/pics/Room_Scenes.gif";
import videosGif from "../../../assets/pics/Videos.gif";
import bathroomPic from "../../../assets/pics/2_BathroomScene_LR.jpg";
import closeUp from "../../../assets/pics/Close-ups.jpg";
import kithenConfigurator from "../../../assets/pics/AR_01.gif";
import screenshot from "../../../assets/pics/screenshot.png";
import logo1 from "../../../assets/pics/logo-1.png";
import logo2 from "../../../assets/pics/logo-2.png";
import logo3 from "../../../assets/pics/logo-3.png";
import logo4 from "../../../assets/pics/logo-4.png";
import logo5 from "../../../assets/pics/logo-5.png";
import logo6 from "../../../assets/pics/logo-6.png";
import logo7 from "../../../assets/pics/logo-7.png";
import logo8 from "../../../assets/pics/logo-8.png";

import angle_1 from "../../../assets/pics/angle_1.jpg";
import angle_4 from "../../../assets/pics/angle_4.jpg";
import angle_8 from "../../../assets/pics/angle_8.jpg";
import angle_16 from "../../../assets/pics/angle_16.jpg";
import angle_27 from "../../../assets/pics/angle_27.jpg";

import PaulSofa_BeigeFabric_BeigeFabric_Black_1 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Black/PaulSofa_BeigeFabric_BeigeFabric_Black_1.jpg";
import PaulSofa_BeigeFabric_BeigeFabric_Chrome_1 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Chrome/PaulSofa_BeigeFabric_BeigeFabric_Chrome_1.jpg";
import PaulSofa_BeigeFabric_Black_Black_1 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Black/PaulSofa_BeigeFabric_Black_Black_1.jpg";
import PaulSofa_BeigeFabric_Black_Chrome_1 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Chrome/PaulSofa_BeigeFabric_Black_Chrome_1.jpg";
import PaulSofa_Cashmere_Black_Black_1 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Black/PaulSofa_Cashmere_Black_Black_1.jpg";
import PaulSofa_Cashmere_Black_Chrome_1 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Chrome/PaulSofa_Cashmere_Black_Chrome_1.jpg";
import PaulSofa_Cashmere_Black_Brass_1 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Brass/PaulSofa_Cashmere_Black_Brass_1.jpg";
import PaulSofa_Cashmere_Cashmere_Black_1 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Black/PaulSofa_Cashmere_Cashmere_Black_1.jpg";
import PaulSofa_Cashmere_Cashmere_Brass_1 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Brass/PaulSofa_Cashmere_Cashmere_Brass_1.jpg";
import PaulSofa_Cashmere_Cashmere_Chrome_1 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Chrome/PaulSofa_Cashmere_Cashmere_Chrome_1.jpg";
import PaulSofa_VelvetGreen_Black_Black_1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Black/PaulSofa_VelvetGreen_Black_Black_1.jpg";
import PaulSofa_VelvetGreen_Black_Gold_1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Gold/PaulSofa_VelvetGreen_Black_Gold_1.jpg";
import PaulSofa_VelvetGreen_Orange_Black_1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_1.jpg";
import PaulSofa_VelvetGreen_Orange_Gold_1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Gold/PaulSofa_VelvetGreen_Orange_Gold_1.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Black_1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Black/PaulSofa_VelvetGreen_VelvetGreen_Black_1.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Gold_1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Gold/PaulSofa_VelvetGreen_VelvetGreen_Gold_1.jpg";

import PaulSofa_BeigeFabric_BeigeFabric_Black_4 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Black/PaulSofa_BeigeFabric_BeigeFabric_Black_4.jpg";
import PaulSofa_BeigeFabric_BeigeFabric_Chrome_4 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Chrome/PaulSofa_BeigeFabric_BeigeFabric_Chrome_4.jpg";
import PaulSofa_BeigeFabric_Black_Black_4 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Black/PaulSofa_BeigeFabric_Black_Black_4.jpg";
import PaulSofa_BeigeFabric_Black_Chrome_4 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Chrome/PaulSofa_BeigeFabric_Black_Chrome_4.jpg";
import PaulSofa_Cashmere_Black_Black_4 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Black/PaulSofa_Cashmere_Black_Black_4.jpg";
import PaulSofa_Cashmere_Black_Chrome_4 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Chrome/PaulSofa_Cashmere_Black_Chrome_4.jpg";
import PaulSofa_Cashmere_Black_Brass_4 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Brass/PaulSofa_Cashmere_Black_Brass_4.jpg";
import PaulSofa_Cashmere_Cashmere_Black_4 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Black/PaulSofa_Cashmere_Cashmere_Black_4.jpg";
import PaulSofa_Cashmere_Cashmere_Brass_4 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Brass/PaulSofa_Cashmere_Cashmere_Brass_4.jpg";
import PaulSofa_Cashmere_Cashmere_Chrome_4 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Chrome/PaulSofa_Cashmere_Cashmere_Chrome_4.jpg";
import PaulSofa_VelvetGreen_Black_Black_4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Black/PaulSofa_VelvetGreen_Black_Black_4.jpg";
import PaulSofa_VelvetGreen_Black_Gold_4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Gold/PaulSofa_VelvetGreen_Black_Gold_4.jpg";
import PaulSofa_VelvetGreen_Orange_Black_4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_4.jpg";
import PaulSofa_VelvetGreen_Orange_Gold_4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Gold/PaulSofa_VelvetGreen_Orange_Gold_4.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Black_4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Black/PaulSofa_VelvetGreen_VelvetGreen_Black_4.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Gold_4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Gold/PaulSofa_VelvetGreen_VelvetGreen_Gold_4.jpg";

import PaulSofa_BeigeFabric_BeigeFabric_Black_8 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Black/PaulSofa_BeigeFabric_BeigeFabric_Black_8.jpg";
import PaulSofa_BeigeFabric_BeigeFabric_Chrome_8 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Chrome/PaulSofa_BeigeFabric_BeigeFabric_Chrome_8.jpg";
import PaulSofa_BeigeFabric_Black_Black_8 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Black/PaulSofa_BeigeFabric_Black_Black_8.jpg";
import PaulSofa_BeigeFabric_Black_Chrome_8 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Chrome/PaulSofa_BeigeFabric_Black_Chrome_8.jpg";
import PaulSofa_Cashmere_Black_Black_8 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Black/PaulSofa_Cashmere_Black_Black_8.jpg";
import PaulSofa_Cashmere_Black_Chrome_8 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Chrome/PaulSofa_Cashmere_Black_Chrome_8.jpg";
import PaulSofa_Cashmere_Black_Brass_8 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Brass/PaulSofa_Cashmere_Black_Brass_8.jpg";
import PaulSofa_Cashmere_Cashmere_Black_8 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Black/PaulSofa_Cashmere_Cashmere_Black_8.jpg";
import PaulSofa_Cashmere_Cashmere_Brass_8 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Brass/PaulSofa_Cashmere_Cashmere_Brass_8.jpg";
import PaulSofa_Cashmere_Cashmere_Chrome_8 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Chrome/PaulSofa_Cashmere_Cashmere_Chrome_8.jpg";
import PaulSofa_VelvetGreen_Black_Black_8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Black/PaulSofa_VelvetGreen_Black_Black_8.jpg";
import PaulSofa_VelvetGreen_Black_Gold_8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Gold/PaulSofa_VelvetGreen_Black_Gold_8.jpg";
import PaulSofa_VelvetGreen_Orange_Black_8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_8.jpg";
import PaulSofa_VelvetGreen_Orange_Gold_8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Gold/PaulSofa_VelvetGreen_Orange_Gold_8.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Black_8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Black/PaulSofa_VelvetGreen_VelvetGreen_Black_8.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Gold_8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Gold/PaulSofa_VelvetGreen_VelvetGreen_Gold_8.jpg";

import PaulSofa_BeigeFabric_BeigeFabric_Black_16 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Black/PaulSofa_BeigeFabric_BeigeFabric_Black_16.jpg";
import PaulSofa_BeigeFabric_BeigeFabric_Chrome_16 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Chrome/PaulSofa_BeigeFabric_BeigeFabric_Chrome_16.jpg";
import PaulSofa_BeigeFabric_Black_Black_16 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Black/PaulSofa_BeigeFabric_Black_Black_16.jpg";
import PaulSofa_BeigeFabric_Black_Chrome_16 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Chrome/PaulSofa_BeigeFabric_Black_Chrome_16.jpg";
import PaulSofa_Cashmere_Black_Black_16 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Black/PaulSofa_Cashmere_Black_Black_16.jpg";
import PaulSofa_Cashmere_Black_Chrome_16 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Chrome/PaulSofa_Cashmere_Black_Chrome_16.jpg";
import PaulSofa_Cashmere_Black_Brass_16 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Brass/PaulSofa_Cashmere_Black_Brass_16.jpg";
import PaulSofa_Cashmere_Cashmere_Black_16 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Black/PaulSofa_Cashmere_Cashmere_Black_16.jpg";
import PaulSofa_Cashmere_Cashmere_Brass_16 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Brass/PaulSofa_Cashmere_Cashmere_Brass_16.jpg";
import PaulSofa_Cashmere_Cashmere_Chrome_16 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Chrome/PaulSofa_Cashmere_Cashmere_Chrome_16.jpg";
import PaulSofa_VelvetGreen_Black_Black_16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Black/PaulSofa_VelvetGreen_Black_Black_16.jpg";
import PaulSofa_VelvetGreen_Black_Gold_16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Gold/PaulSofa_VelvetGreen_Black_Gold_16.jpg";
import PaulSofa_VelvetGreen_Orange_Black_16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_16.jpg";
import PaulSofa_VelvetGreen_Orange_Gold_16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Gold/PaulSofa_VelvetGreen_Orange_Gold_16.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Black_16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Black/PaulSofa_VelvetGreen_VelvetGreen_Black_16.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Gold_16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Gold/PaulSofa_VelvetGreen_VelvetGreen_Gold_16.jpg";

import PaulSofa_BeigeFabric_BeigeFabric_Black_27 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Black/PaulSofa_BeigeFabric_BeigeFabric_Black_27.jpg";
import PaulSofa_BeigeFabric_BeigeFabric_Chrome_27 from "../../../assets/pics/360/PaulSofa_BeigeFabric_BeigeFabric_Chrome/PaulSofa_BeigeFabric_BeigeFabric_Chrome_27.jpg";
import PaulSofa_BeigeFabric_Black_Black_27 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Black/PaulSofa_BeigeFabric_Black_Black_27.jpg";
import PaulSofa_BeigeFabric_Black_Chrome_27 from "../../../assets/pics/360/PaulSofa_BeigeFabric_Black_Chrome/PaulSofa_BeigeFabric_Black_Chrome_27.jpg";
import PaulSofa_Cashmere_Black_Black_27 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Black/PaulSofa_Cashmere_Black_Black_27.jpg";
import PaulSofa_Cashmere_Black_Chrome_27 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Chrome/PaulSofa_Cashmere_Black_Chrome_27.jpg";
import PaulSofa_Cashmere_Black_Brass_27 from "../../../assets/pics/360/PaulSofa_Cashmere_Black_Brass/PaulSofa_Cashmere_Black_Brass_27.jpg";
import PaulSofa_Cashmere_Cashmere_Black_27 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Black/PaulSofa_Cashmere_Cashmere_Black_27.jpg";
import PaulSofa_Cashmere_Cashmere_Brass_27 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Brass/PaulSofa_Cashmere_Cashmere_Brass_27.jpg";
import PaulSofa_Cashmere_Cashmere_Chrome_27 from "../../../assets/pics/360/PaulSofa_Cashmere_Cashmere_Chrome/PaulSofa_Cashmere_Cashmere_Chrome_27.jpg";
import PaulSofa_VelvetGreen_Black_Black_27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Black/PaulSofa_VelvetGreen_Black_Black_27.jpg";
import PaulSofa_VelvetGreen_Black_Gold_27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Black_Gold/PaulSofa_VelvetGreen_Black_Gold_27.jpg";
import PaulSofa_VelvetGreen_Orange_Black_27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_27.jpg";
import PaulSofa_VelvetGreen_Orange_Gold_27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Gold/PaulSofa_VelvetGreen_Orange_Gold_27.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Black_27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Black/PaulSofa_VelvetGreen_VelvetGreen_Black_27.jpg";
import PaulSofa_VelvetGreen_VelvetGreen_Gold_27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_VelvetGreen_Gold/PaulSofa_VelvetGreen_VelvetGreen_Gold_27.jpg";

import "./style.scss";

const Home = ({ openModal, model3D, setModel3D, setOpenedModal }) => {
  let [fabric, setFabric] = useState("Cashmere");
  let [piping, setPiping] = useState("Black");
  let [leg, setLeg] = useState("Black");
  let [preview, setPreview] = useState("PaulSofa_Cashmere_Black_Black_1");
  let [fabrics] = useState(["Cashmere", "BeigeFabric", "VelvetGreen"]);
  let [pipings, setPipings] = useState(["Black", "Cashmere"]);
  let [legs, setLegs] = useState(["Black", "Chrome", "Brass"]);
  let [angle, setAngle] = useState("1"); // 1 ,4, 8, 16, 27
  // let [types] = useState(["name", "fabric", "piping", "leg"]);
  // let [name] = useState(["PaulSofa"]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const refactorReview = () => {
    const x = preview.split("_");
    x.pop();
    return x.join("_");
  };

  const setPreviewPicture = (data) => {
    if (data.type === "fabric") {
      setPiping("Black");
      setLeg("Black");
      setFabricHandler(data.value);
      setPreview(`${"PaulSofa"}_${data.value}_${"Black"}_${"Black"}_${angle}`);
    } else if (data.type === "piping") {
      setPiping(data.value);
      setPreview(`${"PaulSofa"}_${fabric}_${data.value}_${leg}_${angle}`);
    } else if (data.type === "leg") {
      setLeg(data.value);
      setPreview(`${"PaulSofa"}_${fabric}_${piping}_${data.value}_${angle}`);
    } else if (data.type === "angle") {
      setAngle(data.value);
      setPreview(`${"PaulSofa"}_${fabric}_${piping}_${leg}_${data.value}`);
    } else {
      console.log("No select");
      setPreview(`${"PaulSofa"}_${data.value}_${"Black"}_${"Black"}_${angle}`);
    }
  };

  const setFabricHandler = (val) => {
    setFabric(val);

    if (val === "Cashmere") {
      setPipings(["Black", "Cashmere"]);
      setLegs(["Black", "Chrome", "Brass"]);
    } else if (val === "BeigeFabric") {
      setPipings(["Black", "BeigeFabric"]);
      setLegs(["Black", "Chrome"]);
    } else if (val === "VelvetGreen") {
      setPipings(["Black", "Orange", "VelvetGreen"]);
      setLegs(["Black", "Gold"]);
    } else {
      return;
    }
  };

  return (
    <div id='Home'>
      <div className='container'>
        <div id='hero' className='intro'>
          <p className='biggerText'>3D Product Visualization Made Easy</p>
          <p>
            Built by 3D & Visual <span className='highlighted'>Experts</span>{" "}
            for
          </p>
          <h1>Home & Living</h1>
          <p className='subtext'>
            Showcase your products in a new and innovative way.
          </p>
        </div>
      </div>

      <div className='container'>
        <div className='main'>
          <div className='left'>
            <h2>
              Boost your online sales with highly customizable visual content
              for your products
            </h2>
            <p>
              Apto helps retail and e-commerce companies build Room Scenes, 360
              Views, Videos, Close ups and more.
            </p>
            <p>
              Our configurator allows your customers to create and choose the
              ideal product for their space.
            </p>
            <PrimaryButton
              className='moveLeft'
              label='Request Consultations'
              to='contact'
              offset={0}
            />
          </div>
          <div className='right'>
            <img draggable='false' src={imgMain} alt='' />
          </div>
        </div>
      </div>

      <div className='logos'>
        <div className='logo'>
          <img src={logo1} alt='' />
        </div>
        <div className='logo'>
          <img src={logo2} alt='' />
        </div>
        <div className='logo'>
          <img src={logo3} alt='' />
        </div>
        <div className='logo'>
          <img src={logo4} alt='' />
        </div>
        <div className='logo'>
          <img src={logo5} alt='' />
        </div>
        <div className='logo'>
          <img src={logo6} alt='' />
        </div>
        <div className='logo'>
          <img src={logo7} alt='' />
        </div>
        <div className='logo'>
          <img src={logo8} alt='' />
        </div>
      </div>

      <div className='container'>
        <div id='offers' className='title'>
          <h1>We Offer</h1>
          <p className='highlighted'>
            Fast and easy solutions for a growing business.
          </p>
        </div>
      </div>

      <div className='container'>
        <div className='configurator'>
          <div className='configuratorTextBox'>
            <h2>3D product configurator</h2>
            <p>
              Increase revenue by having customers configure products on the
              spot
            </p>
            <p>
              Show any product, from any angle, in any variation, give prices
              and enable online ordering.
            </p>
          </div>
          <div className='angles'>
            <div className='left'>
              <div className='bordered screenshot'>
                <img className='screenshotImg' src={screenshot} alt='..' /> Take
                a screenshot
              </div>
              <img
                draggable='false'
                className={angle === "1" ? "showSmall" : "blureSmall"}
                onClick={() =>
                  setPreviewPicture({
                    type: "angle",
                    value: "1",
                  })
                }
                src={angle_1}
                alt=''
              />
              <img
                draggable='false'
                className={angle === "4" ? "showSmall" : "blureSmall"}
                onClick={() =>
                  setPreviewPicture({
                    type: "angle",
                    value: "4",
                  })
                }
                src={angle_4}
                alt=''
              />
              <img
                draggable='false'
                className={angle === "8" ? "showSmall" : "blureSmall"}
                onClick={() =>
                  setPreviewPicture({
                    type: "angle",
                    value: "8",
                  })
                }
                src={angle_8}
                alt=''
              />
              <img
                draggable='false'
                className={angle === "16" ? "showSmall" : "blureSmall"}
                onClick={() =>
                  setPreviewPicture({
                    type: "angle",
                    value: "16",
                  })
                }
                src={angle_16}
                alt=''
              />
              <img
                draggable='false'
                className={angle === "27" ? "showSmall" : "blureSmall"}
                onClick={() =>
                  setPreviewPicture({
                    type: "angle",
                    value: "27",
                  })
                }
                src={angle_27}
                alt=''
              />
            </div>
            <div className='right'>
              <p>Apto 3D configurator</p>
            </div>
          </div>
          <div className='configuratorMain'>
            <div className='left'>
              <div className='picWrapper'>
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Chrome_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Chrome_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Chrome_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Chrome_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Chrome_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Chrome_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Chrome_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Chrome_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_BeigeFabric_Chrome_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_BeigeFabric_Chrome_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Brass_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Brass_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Brass_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Brass_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Brass_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Brass_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Brass_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Brass_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Brass_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Brass_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Chrome_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Chrome_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Chrome_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Chrome_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Chrome_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Chrome_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Chrome_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Chrome_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_BeigeFabric_Black_Chrome_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_BeigeFabric_Black_Chrome_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Chrome_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Chrome_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Chrome_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Chrome_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Chrome_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Chrome_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Chrome_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Chrome_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Black_Chrome_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Black_Chrome_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Brass_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Brass_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Brass_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Brass_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Brass_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Brass_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Brass_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Brass_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Brass_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Brass_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Chrome_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Chrome_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Chrome_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Chrome_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Chrome_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Chrome_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Chrome_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Chrome_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_Cashmere_Cashmere_Chrome_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_Cashmere_Cashmere_Chrome_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Gold_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Gold_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Gold_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Gold_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Gold_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Gold_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Gold_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Gold_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Black_Gold_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Black_Gold_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Gold_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Gold_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Gold_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Gold_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Gold_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Gold_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Gold_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Gold_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Gold_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Gold_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_Orange_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_Orange_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Black_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Black_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Black_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Black_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Black_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Black_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Black_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Black_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Black_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Black_27}
                  alt=''
                />

                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Gold_1"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Gold_1}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Gold_4"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Gold_4}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Gold_8"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Gold_8}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Gold_16"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Gold_16}
                  alt=''
                />
                <img
                  draggable='false'
                  className={
                    preview !== "PaulSofa_VelvetGreen_VelvetGreen_Gold_27"
                      ? "hidePic"
                      : "showPic"
                  }
                  src={PaulSofa_VelvetGreen_VelvetGreen_Gold_27}
                  alt=''
                />
                <p className='picTextUp'>New way to configure your products.</p>
                <div className='picText'>
                  <img
                    className='hideOnTablet icon3d'
                    src={roundView}
                    alt='3d'
                  />
                  <p
                    onClick={() => {
                      setOpenedModal(`${refactorReview()}`);
                      openModal();
                    }}
                    className='bordered hideOnTablet'
                  >
                    Click to open.
                  </p>
                  <p className='hideOnTablet'>
                    Select a configuration, click 360 view icon <br /> and see
                    the product from every angle
                  </p>
                </div>
              </div>
            </div>
            <div className='right'>
              <div className='blackBg'>
                <div className='mainArea'>
                  <div className='boxItem'>
                    <div>
                      <span>Design your own</span>
                      <span>Price</span>
                    </div>
                    <div>
                      <span className='highlighted'>Sofa</span>
                      <span className='highlighted'>$1450</span>
                    </div>
                  </div>

                  <div className='boxItemSelect'>
                    <div>
                      <p className='highlighted'>Fabric finish</p>
                      <p>{fabric}</p>
                    </div>
                    <div className='options'>
                      {fabrics.map((f, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() =>
                              setPreviewPicture({
                                type: "fabric",
                                value: f,
                              })
                            }
                            className={`optionItem ${f} ${
                              f === fabric && "selected"
                            }`}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                  <div className='boxItemSelect'>
                    <div>
                      <p className='highlighted'>Piping Finish</p>
                      <p>{piping}</p>
                    </div>
                    <div className='options'>
                      {pipings.map((p, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() =>
                              setPreviewPicture({
                                type: "piping",
                                value: p,
                              })
                            }
                            className={`optionItem ${p} ${
                              p === piping && "selected"
                            }`}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                  <div className='boxItemSelect'>
                    <div>
                      <p className='highlighted'>Leg Finish</p>
                      <p>{leg}</p>
                    </div>
                    <div className='options'>
                      {legs.map((l, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() =>
                              setPreviewPicture({
                                type: "leg",
                                value: l,
                              })
                            }
                            className={`optionItem ${l} ${
                              l === leg && "selected"
                            }`}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='btnCart'>
                  <p>Add to cart</p>
                </div>
              </div>
            </div>
          </div>
          <div className='configuratorTextBox'>
            <p>
              <span className='highlighted'>Unlimited Customization.</span> Show
              all product variations.
            </p>
            <p>
              <span className='highlighted'>Fast 360 Views.</span> Show product
              from all angles.
            </p>
            <p>
              <span className='highlighted'>4K HD Zoom.</span> High definition
              one-click zoom.
            </p>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='views'>
          <div className='boxes first360'>
            <div className='textWrapper'>
              <p className='boxTitle'>360° views</p>
              <p>Giving you unlimited angles to choose from.</p>
            </div>
            <div className='picWrapper'>
              <img
                draggable='false'
                className={model3D === "blue" ? "hidePic" : ""}
                src={img360}
                alt=''
              />
              <img
                draggable='false'
                className={model3D === "red" ? "hidePic" : ""}
                src={img360Blue}
                alt=''
              />
            </div>
            <div className='boxFooter'>
              <img
                className='hideOnTablet icon3d'
                onClick={() => {
                  if (model3D === "red") {
                    setOpenedModal("Red_Sofa");
                  } else if (model3D === "blue") {
                    setOpenedModal("Blue_Sofa");
                  }
                  openModal();
                }}
                src={roundView}
                alt='3d'
              />
              <div className='dots'>
                <img
                  className={model3D === "blue" ? "selected" : ""}
                  onClick={() => {
                    setOpenedModal("");
                    setModel3D("blue");
                  }}
                  src={dotBlue}
                  alt=''
                />
                <img
                  className={model3D === "red" ? "selected" : ""}
                  onClick={() => {
                    setOpenedModal("");
                    setModel3D("red");
                  }}
                  src={dotRed}
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='boxes boxBlack noMarginRight'>
            <div className='textWrapper'>
              <p className='boxTitle'>Room Scenes</p>
              <p>
                Have us create custom visuals or choose from our extensive
                readymade library. Quickly update your scenes by changing
                colors/materials.
              </p>
            </div>
            <div className='picWrapper'>
              <img draggable='false' src={imgRoom3d} alt='' />
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='views'>
          <div className='boxes boxPink'>
            <div className='textWrapper'>
              <p className='boxTitle'>Close ups</p>
              <p>Show your product’s details.</p>
            </div>
            <div className='picWrapper'>
              <img draggable='false' src={closeUp} alt='' />
            </div>
          </div>
          <div className='boxes noMarginRight'>
            <div className='textWrapper'>
              <p className='boxTitle'>Videos</p>
              <p>Create original videos for websites and advertising.</p>
            </div>
            <div className='picWrapper'>
              <img draggable='false' src={videosGif} alt='' />
            </div>
          </div>
        </div>
      </div>

      <div className='banner'>
        <h1>Dynamic customer experience</h1>
        <PrimaryButton
          to='contact'
          offset={0}
          className='btn1'
          label='Request Consultations'
        />
      </div>

      <div className='container'>
        <div className='offers'>
          <div className='left'>
            <p>
              <span className='highlighted green'>Converting</span> your product
              into reusable 3d assets.
            </p>
            <PrimaryButton to='process' offset={-50} label='How to' />
            <div className='boxes'>
              <div className='box'>
                <div className='leftPart'>
                  <p className='pink'>1</p>
                </div>
                <div className='rightPart pink'>
                  <p>Send us simple product data.</p>
                </div>
              </div>
              <div className='box'>
                <div className='leftPart'>
                  <p>2</p>
                </div>
                <div className='rightPart'>
                  <p>We create your renders at lightning speed.</p>
                </div>
              </div>
              <div className='box'>
                <div className='leftPart'>
                  <p className='green'>3</p>
                </div>
                <div className='rightPart green'>
                  <p>Get photorealistic visuals in limitless variations.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='right'>
            <p>
              Unlimited high quality product{" "}
              <span className='highlighted orange'>visuals.</span>
            </p>
            <div className='picWrapper'>
              <img draggable='false' src={bathroomPic} alt='' />
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='customizable'>
          <p>
            Fully <span className='highlighted orange'>customizable</span>{" "}
            visual content for your products, allowing you to reuse one master
            3d asset.
          </p>
          <div className='picWrapper'>
            <img draggable='false' src={customisablePic} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='increaseSales'>
          <div className='textBox'>
            <h2>Augmented Reality</h2>
            <p>
              Enables customers to see your product in their surroundings true
              to size.
            </p>
          </div>
          <img src={kithenConfigurator} alt='...' />
        </div>
      </div>

      <div className='container'>
        <div id='need' className='whyNeedUsBox'>
          <h1>Why you need us</h1>
          <div className='whyNeedBoxes'>
            <div className='div1'>
              <p>
                To increase your online sales and make your{" "}
                <span className='bolded'>products</span> and{" "}
                <span className='bolded'>brand</span> stand out. Show all that
                you can offer so you can sell more.
              </p>
              <p className='dividerP'></p>
              <p>
                <span className='bolded'>Cut Costs. </span>
                Out with the old in with the new – regular photoshoots need
                equipment, props, photographers, studio, logistics and so much
                more, while you only need US.
              </p>
            </div>
            <div className='div2'>
              <p>
                <span className='bolded upText'>
                  Keep up with ever changing markets.
                </span>
              </p>
              <PrimaryButton
                offset={-100}
                to='prices'
                className='greyBg'
                label='Get started'
              />
            </div>
            <div className='div3'>
              <p>
                To shorten the customers’ decision- making process and increase
                confidence in their choice – fewer returns and full customer
                satisfaction.
              </p>
              <p className='dividerP'></p>
              <p>
                <span className='bolded'>Improve customer experience</span>{" "}
                <br />
                Modern day customers expect at least 6 images before purchasing.
              </p>
            </div>
          </div>
          {/* <PrimaryButton
            offset={-100}
            to='prices'
            className='greyBg'
            label='Bundle deals'
          /> */}
        </div>
      </div>

      <div className='container'>
        <div className='iconsBox'>
          <p className='titleLarge'>
            Created visuals can be used to improve performance across all
            touchpoints:
          </p>
          <div className='iconBoxes'>
            <div className='greenIcon'>
              <i className='fa-solid fa-globe'></i>
              <p>Websites</p>
            </div>
            <div className='pinkIcon'>
              <i className='fa-solid fa-icons'></i>
              <p>Social media platforms</p>
            </div>
            <div className='blueIcon'>
              <i className='fa-solid fa-print'></i>
              <p>Print</p>
            </div>
            <div className='orangeIcon'>
              <i className='fa-solid fa-cart-shopping'></i>
              <p>In-store</p>
            </div>
            <div className='yellowIcon'>
              <i className='fa-solid fa-envelope'></i>
              <p>Email</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='iconsBox2'>
          <div className='iconBoxes2'>
            <div className='xxx'>
              <i className='fa-regular fa-clock'></i>
              <p>
                Still photography is outdated and{" "}
                <span className='bolded'>time-consuming</span>.
              </p>
            </div>
            <div className='xxx'>
              <i className='fa-solid fa-dollar-sign'></i>
              <p>
                Photoshoots are too <span className='bolded'>expensive</span>{" "}
                (building sets, vesting product and materials, hiring...)
              </p>
            </div>
            <div className='yyy'>
              <i className='fa-solid fa-camera'></i>
              <p>
                <span className='bolded'>Single use</span> <br />
                no option to change color, room set or view angle later on.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div id='process' className='processTitle'>
          <p>Behind the Apto</p>
          <h1>The process.</h1>
        </div>
      </div>

      <div className='container'>
        <div className='processSubitle'>
          <p className='number'>1</p>
          <p className='biggerText'>Product data collecting</p>
          <p>
            Provide us with product images, dimensions, specifications and
            swatches, so we can create products with incredible realism.
          </p>
        </div>
      </div>
      <div className='container'>
        <div className='processOne'>
          <div className='middle'>
            <img draggable='false' src={imgProcess1} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='processSubitle'>
          <p className='number'>2</p>
          <p className='biggerText'>Preferences</p>
          <p>
            Tell us your choice for scenes, lighting and camera angle. The same
            process will be applied to all your products to give your customers
            a unique opportunity to fully review all aspects of your products.
          </p>
        </div>
      </div>
      <div className='container'>
        <div className='processTwo'>
          <div className='middle'>
            <img draggable='false' src={imgProcess2} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='processSubitle'>
          <p className='number'>3</p>
          <p className='biggerText'>Easy content approval</p>
          <p>
            Use a quick app provided by us to speed up communication and
            minimize time spent in the review process.
          </p>
        </div>
      </div>
      <div className='container'>
        <div className='processThree'>
          <h2 className='sideTitle'>
            Work Smarter. <br />
            Finish faster.
          </h2>
        </div>
      </div>

      <div className='container'>
        <div className='processFooter'>
          <h1>One seamless experience.</h1>
        </div>
      </div>

      <div className='container'>
        <div id='prices' className='title priceTitle'>
          <h1>Apto Pricing</h1>
          <p>
            We create custom pricing based on your specific needs and budget.
          </p>
        </div>
      </div>

      <div className='container'>
        <div className='prices'>
          <div className='priceText'>
            <div className='left'>
              <h2>
                Best-in-class product experiences with 3D and Augmented Reality
              </h2>
              <p>Have questions about pricing?</p>
              <a className='blued' href='mailto: info@apto3d.com'>
                Chat with an Apto specialist {">"}
              </a>
            </div>
            <div className='right'>
              <PrimaryButton
                className='aaa'
                label={"Get pricing"}
                hrefTo='mailto: info@apto3d.com'
              />
            </div>
          </div>
          <h2>The best way to get pricing:</h2>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <p className='titlePrice'>1</p>
              <p>
                <span className='highlighted'>Schedule a meeting with our</span>
                <br />
                <span className='highlighted'>specialists.</span>
                <br />
                We will help you decide what is the <br />
                best fit for your business needs.
              </p>
            </div>
            <div className='priceBox'>
              <p className='titlePrice'>2</p>
              <p>
                <span className='highlighted'>
                  Fill out Apto process survey.
                </span>
                <br />
                So we can have all the specifics –
                <br />
                amount of products, type of <br />
                visualization, time and budget.
              </p>
            </div>
            <div className='priceBox'>
              <p className='titlePrice'>3</p>
              <p>
                <span className='highlighted'>Get the quote.</span>
                <br />
                You will receive a pricing presentation
                <br />
                to review with all the costs clearly <br />
                explained and decide if you need to <br />
                make any changes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div id='contact' className='contactUs'>
          <h1>Contact us</h1>
          <p>
            Contact us via{" "}
            <a className='highlighted' href='mailto: info@apto3d.com'>
              info@apto3d.com
            </a>{" "}
            and we will get back to you to schedule a meeting and discuss how we
            can bring your products to life.
          </p>
        </div>
      </div>
      <div className='container'>
        <div className='footer'>
          <p>Apto 2022. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
