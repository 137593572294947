import React from "react";
import { Link } from "react-scroll";
import PrimaryButton from "../../atoms/PrimaryButton";
import logoPink from "../../../assets/pics/virtualExperience/logo_purple.png";
import spiningChair from "../../../assets/pics/virtualExperience/Armchair_01_GIF_Final.gif";
import AugmentedReality from "../../../assets/pics/virtualExperience/AugmentedReality.jpg";
import film from "../../../assets/pics/virtualExperience/ProductSubstanceFilm_GIF_1600x700.gif";
import sofa from "../../../assets/pics/virtualExperience/Sofa_Living_GIF-1600x700.gif";
import volume from "../../../assets/pics/virtualExperience/VolumeProductImagery.jpg";
import logo1 from "../../../assets/pics/logo-1.png";
import logo2 from "../../../assets/pics/logo-2.png";
import logo3 from "../../../assets/pics/logo-3.png";
import logo4 from "../../../assets/pics/logo-4.png";
import logo5 from "../../../assets/pics/logo-5.png";
import logo6 from "../../../assets/pics/logo-6.png";
import logo7 from "../../../assets/pics/logo-7-white.png";
import logo8 from "../../../assets/pics/logo-8.png";

import "./style.scss";

const VirtualExperience = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id='VirtualExperience'>
      <div id='top_main' className='main'>
        <img src={logoPink} alt='' />
        <h1>Virtual Experience</h1>
        <p>
          Revolutionizing the way people experience and visualize their dream
          spaces with cuting-edge 3d content creation at speed and scale.
        </p>
      </div>

      <div className='sub-main'>
        <h2>
          3D CONTENT <br /> MADE EASY.
        </h2>
        <p>
          Bring your Home & Living products to life with our virtual experience
          platform.
        </p>
        <p>
          Our ultimate virtual experience platform for Home & Living creates
          lifelike 3D Digital Twins of your products, <br /> helping customers
          envision their dream spaces with ease.
        </p>
        <p>
          Our advanced technology and expert team deliver the most immersive and
          interactive experiences in the industry, <br /> from 360 product spins
          and volume product imagery to augmented reality, product substance
          films and product configurators.
        </p>
        <p>
          Make a lasting emotional connection with your audience and drive sales
          like never before.
        </p>
      </div>

      <h2 className='pinkText'>MAKING PHYSICAL – DIGITAL</h2>
      <div className='container'>
        <div id='spin360' className='pic'>
          <img src={spiningChair} alt='' />
          <p>360 SPINS</p>
        </div>
      </div>
      <div className='container'>
        <div id='how_it_works' className='pic'>
          <img src={AugmentedReality} alt='' />
          <p>AUGMENTED REALITY</p>
        </div>
      </div>
      <div className='container'>
        <div id='imagery' className='pic'>
          <img src={volume} alt='' />
          <p>
            VOLUME <br /> PRODUCT IMAGERY
          </p>
        </div>
      </div>
      <div className='container'>
        <div id='film' className='pic'>
          <img src={film} alt='' />
          <p>
            PRODUCT <br /> SUBSTANCE FILM
          </p>
        </div>
      </div>
      <div className='container'>
        <div id='configurator' className='pic'>
          <img src={sofa} alt='' />
          <p>
            PRODUCT <br /> CONFIGURATOR
          </p>
        </div>
      </div>

      <h2 className='whiteText marginUp'>
        POSSIBILITIES ARE LIMITED ONLY <br />
        BY YOUR IMAGINATION.
      </h2>

      <h1 className='gradient virtualText'>VIRTUAL EXPERIENCE</h1>
      <p className='whiteText marginDown'>3D content creation made easy.</p>

      <div className='container'>
        <div className='boxWrap'>
          <div className='box'>
            <h3 className='pinkText1'>VOLUME PRODUCT IMAGERY</h3>
            <p className='sub'>Showcase every product variation.</p>
            <p>
              Create millions of high-quality CG Images for every product, range
              and style. Get to market fast and easy with cut outs, silos, 360
              spins or lifestyle shots for every channel: online, print and
              more.
            </p>
          </div>
          <div className='box'>
            <h3 className='pinkText2'>360 PRODUCT SPINS</h3>
            <p className='sub'>
              Experience every angle with stunning 360 product spins.
            </p>
            <p>
              Our 360 product spins provide a seamless and engaging way for your
              customers to explore your products from every angle. With
              crystal-clear detail and smooth rotation, your customers can now
              get up close and personal with your products, inspiring trust and
              confidence in their buying decisions.
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='boxWrap'>
          <div className='box'>
            <h3 className='pinkText3'>PRODUCT SUBSTANCE FILMS</h3>
            <p className='sub'>
              Bring your products to life with animated product videos.
            </p>
            <p>
              Our animated product videos provide a captivating and informative
              way to showcase your products in action. Through expert
              storytelling and high-quality visuals, we create an emotional
              connection with your customers, helping them understand the value
              and features of your products while keeping them engaged through
              their buying journey.
            </p>
          </div>
          <div className='box'>
            <h3 className='pinkText4'>AUGMENTED REALITY</h3>
            <p className='sub'>Visualize your dream space with AR.</p>
            <p>
              Our cutting-edge AR technology allows your customers tp visualize
              your products in their own home, providing a realistic and
              immersive experience that will leave a lasting impression. Let
              your customers see how your products will look and feel in their
              space, eliminating guesswork and driving more confident purchases.
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='boxWrap'>
          <div className='box'>
            <h3 className='pinkText5'>PRODUCT CONFIGURATORS</h3>
            <p className='sub'>
              Personalize every detail with our product configurators.
            </p>
            <p>
              Our product configurators empower your customers to create their
              dream spaces by customizing every aspect of your products. From
              millions of colors, materials and finishes to sizes and features,
              let your customers tailor their choices to their unique
              preferences, resulting in increased satisfaction and loyalty.
              Customizable environment with endless possibilities.
            </p>
          </div>
        </div>
      </div>

      <div className='textGradientBox'>
        <h1 className='gradient'>
          REVOLUTIONIZE <br /> YOUR MARKETING STRATEGY
        </h1>
        <p>
          Boost your marketing efforts with our cutting-edge virtual experience
          platform, <br /> designed to captivate your audience and generate
          remarkable results.
        </p>
        <p>
          Our innovative marketing automation solutions empower businesses to
          create customized promotional content <br /> in mere minutes, rather
          than months, significantly reducing the time and effort required
          compared to traditional methods.
        </p>
      </div>

      <div className='container'>
        <div className='textButton'>
          <h2>
            AT YOUR SERVICE <br /> EVERY STEP OF THE WAY.
          </h2>
          <PrimaryButton
            label='Request a consultation'
            hrefTo='mailto: hello@apto3d.com'
          />
        </div>
      </div>

      <div className='textGradientBox'>
        <h1 className='gradient'>
          REVOLUTIONARY IMPACT <br />
          INFINITE POTENTIAL
        </h1>
        <p>
          Our exceptional platform yields outstanding outcomes by significantly
          boosting output levels beyond the capabilities <br /> of conventional
          production methods. This not only saves valuable time and resources
          but also ensures a swifter market launch.
        </p>
      </div>

      <div className='container'>
        <div className='stats'>
          <div className='stat'>
            <p>ANNUAL IMAGE OUTPUT EXCEED</p>
            <h2 className='pinkText1'>10 M</h2>
          </div>
          <div className='stat'>
            <p>REDUCE SAMPLE MILEAGE BY</p>
            <h2 className='pinkText1'>70%</h2>
          </div>
          <div className='stat'>
            <p>SAVE ANNUAL STORAGE</p>
            <h2 className='pinkText1'>{">100TB"}</h2>
          </div>
        </div>
      </div>

      <div className='logos'>
        <div className='logo'>
          <img src={logo1} alt='' />
        </div>
        <div className='logo'>
          <img src={logo2} alt='' />
        </div>
        <div className='logo'>
          <img src={logo3} alt='' />
        </div>
        <div className='logo'>
          <img src={logo4} alt='' />
        </div>
        <div className='logo'>
          <img src={logo5} alt='' />
        </div>
        <div className='logo'>
          <img src={logo6} alt='' />
        </div>
        <div className='logo'>
          <img src={logo7} alt='' />
        </div>
        <div className='logo'>
          <img src={logo8} alt='' />
        </div>
      </div>

      <div className='padding1'>
        <h2 className='whiteText'>
          GET ON THAT TRAIN <br />
          BEFORE ITS TOO LATE.
        </h2>
        <p className='whiteText marginDown2'>
          Ready to transform your customers home and living experiences? <br />{" "}
          Create amazing 3D content at speed and scale - without <br />{" "}
          compromising on creativity.
        </p>
        <p className='whiteText'>
          Connect with us today to discover how our innovative virtual <br />{" "}
          experience platform can take your business to new heights. <br /> Let
          apto help you create unforgettable and emotionally resonant <br />{" "}
          experiences that drive sales and build lasting customer relationships.
        </p>
        <div className='btnWrapper'>
          <PrimaryButton
            className='aaa'
            label={"LET'S TALK"}
            hrefTo='mailto: hello@apto3d.com'
          />
        </div>
      </div>

      <div className='bottomLinks'>
        <span>
          <Link
            className='link'
            to='imagery'
            spy={true}
            smooth={true}
            duration={700}
          >
            | Volume Product Imagery
          </Link>
        </span>
        <span>
          {" "}
          <Link
            className='link'
            to='spin360'
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
          >
            | 360 |
          </Link>
        </span>{" "}
        <br />
        <span>
          <Link
            className='link'
            to='how_it_works'
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
          >
            | Augmented Reality
          </Link>
        </span>
        <span>
          {" "}
          <Link
            className='link'
            to='configurator'
            spy={true}
            smooth={true}
            duration={700}
          >
            | Product Configurator |
          </Link>
        </span>{" "}
        <br />
        <span>
          <Link
            className='link'
            to='film'
            spy={true}
            smooth={true}
            duration={700}
          >
            | Product Substance Film |
          </Link>
        </span>
      </div>

      <div id='contact' className='contact'>
        <h4>Contact us</h4>
        <p>
          <a className='highlighted linkEmail' href='mailto: hello@apto3d.com'>
            hello@apto3d.com
          </a>
        </p>
        <p>
          Contact us and we will get back to you to schedule a meeting and
          discuss how we can bring your products to life.
        </p>
      </div>
    </div>
  );
};

export default VirtualExperience;
